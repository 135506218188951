import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../components/js/layout'
import Eye from '../images/eye.inline.svg'
import Waveform from '../images/waveform.inline.svg'
import Chat from '../images/chat.inline.svg'
import Heart from '../images/heart.inline.svg'
import Light from '../images/light.inline.svg'
import Render from '../images/render.inline.svg'

export default function Features() {
  const {t} = useTranslation()

  return (
        <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t('AI & Dexter Education')}</title>
          <link rel="canonical" href="https://dexterprogram.com/" />
          <meta name="description" content={t('Dexter is a fun, interactive memory program powered by AI. It was designed by memory coaching experts with a proven track record of helping tens of thousands of people train their memory, technical math skills, processing and reading speeds.')} />
        </Helmet>
        <Layout>

        <section className="relative bg-gray-200 bg-gradient-to-l from-gray-50 via-gray-100">
          <div className="container fluid mx-auto flex flex-col sm:flex-row sm:items-center my-0 p-6 sm:h-32">
            <div className="flex flex-col w-full justify-center items-start sm:items-center">
              <h1 className="text-xl sm:text-3xl mb-1 uppercase font-semibold">{t('AI & Dexter Education')}</h1>
              <h2 className="txt-sm">{t('AI can help create a better learning experience')}</h2>
            </div>
          </div>
          <div className="svg-wrapper bg-gray-200 bg-gradient-to-l from-gray-50 via-gray-100 block">
            <svg viewBox="0 0 1440 320" preserveAspectRatio="none">
              <path fill="#FFFFFF" fillOpacity="1" d="M0,192L34.3,181.3C68.6,171,137,149,206,122.7C274.3,96,343,64,411,69.3C480,75,549,117,617,133.3C685.7,149,754,139,823,112C891.4,85,960,43,1029,53.3C1097.1,64,1166,128,1234,154.7C1302.9,181,1371,171,1406,165.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
            </svg>
          </div>
        </section>

        <section className="mt-6 sm:mb-6">
          <div className="container fluid mx-auto flex flex-col sm:flex-row items-center my-0">
            <div className="flex flex-col sm:w-1/2 justify-center items-start px-6 pt-0 pb-6 sm:py-8">
              <h1 className="mb-3 text-xl font-semibold">{t('Personalized Learning')}</h1>
              <p className="mb-3 sm:pr-12">{t('With each memory module we have the ability to personalize the curriculum based off the students age, geolocation, passions and strengths. We can tailor each exercise to have the greatest impact on the child.')}</p>
              <p className="mb-3 sm:pr-12">{t('We can ask things like what kind of pets they have, what their hobbies are, or what their favorite school subject is and incorporate that information into the memorization exercise.')}</p>
              <p className="sm:pr-12">{t('We can also form memorizations based off of the students geographic location so for example if they live in Chile we can ask them to memorize the five longest rivers in Chile.')}</p>
            </div>
            <div className="flex flex-col sm:w-1/2 justify-center items-start p-0 m-0 sm:mr-6">
              <img className="mb-0 shadow-2xl" src="/stem-t4l--PnSpCHYKsw-unsplash.jpg" alt="" />
            </div>
          </div>
        </section>

        <div className="svg-wrapper hidden sm:block">
          <svg viewBox="0 0 1440 320" preserveAspectRatio="none" className="fill-current">
            <path fill="#f7fafc" fillOpacity="1" d="M0,192L34.3,181.3C68.6,171,137,149,206,122.7C274.3,96,343,64,411,69.3C480,75,549,117,617,133.3C685.7,149,754,139,823,112C891.4,85,960,43,1029,53.3C1097.1,64,1166,128,1234,154.7C1302.9,181,1371,171,1406,165.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
          </svg>
        </div>

        <section className="pb-0 sm:pb-12 py-6 bg-gray-100 bg-gradient-to-l from-gray-50 via-gray-100">
          <div className="container fluid mx-auto flex flex-col sm:flex-row items-center my-0">
            <div className="flex flex-col sm:w-1/2 justify-center items-start px-6 py-8">
              <h1 className="mb-2 text-xl font-semibold">{t('Heart & Mind')}</h1>
              <h2 className="font-semibold mb-3">{t('Going beyond information')}</h2>
              <p className="mb-3">{t('When a student gets stuck AI Dexter will be able to ask them questions to figure out what they’re struggling with and offer support.')}</p>
              <p>{t('The right encouragement at the right time will help drive a student to success. AI Dexter will be able to read the child’s facial expressions as well as ask the child questions to determine their emotional state and help them stay engaged.')}</p>
              <p>{t('With AI we have the ability to help the student feel supported and like they have someone to lean on if they get stuck.')}</p>
            </div>
            <div className="flex flex-col sm:w-1/2 justify-center items-start p-0 m-0 sm:mr-6">
              <img className="mb-0 shadow-2xl" src="/ben-mullins-je240KkJIuA-unsplash.jpg" alt="" />
            </div>
          </div>
        </section>

        <div className="svg-wrapper bg-gray-100 hidden sm:block">
          <svg viewBox="0 0 1440 320" preserveAspectRatio="none">
            <path fill="#FFFFFF" fillOpacity="1" d="M0,192L34.3,181.3C68.6,171,137,149,206,122.7C274.3,96,343,64,411,69.3C480,75,549,117,617,133.3C685.7,149,754,139,823,112C891.4,85,960,43,1029,53.3C1097.1,64,1166,128,1234,154.7C1302.9,181,1371,171,1406,165.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
          </svg>
        </div>

        <section className="mt-12 sm:mt-6">
          <div className="container fluid mx-auto flex flex-col sm:flex-row items-center mt-0 mb-6">
            <div className="flex flex-col w-full justify-center px-6">
              <h1 className="mb-0 sm:text-xl text-center font-semibold">{t('Capabilities Interaction Loop')}</h1>
              <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-2 mt-5 sm:mt-0">
                <div className="flex items-center h-32 sm:h-32 p-2 border-r-2 border-b-2 border-gray-300 sm:border-0">
                  <div className="relative w-full">
                    <div className="flex mx-auto w-12 h-12 p-2 mt-2 self-center items-center bg-gray-800">
                      <Eye className="fill-current text-white mx-auto" />
                    </div>
                    <h3 className="absolute w-full mt-2 truncate text-xs text-blue-800 font-semibold font-sans uppercase text-center">{t('Perception')}</h3>
                  </div>
                </div>
                <div className="flex items-center h-32 sm:h-32 p-2 p-2 border-b-2 border-gray-300 sm:border-0">
                  <div className="relative w-full">
                    <div className="flex mx-auto w-12 h-12 p-2 mt-2 self-center items-center bg-gray-800">
                      <Light className="fill-current text-white mx-auto" />
                    </div>
                    <h3 className="absolute w-full mt-2 truncate text-xs text-blue-800 font-semibold font-sans uppercase text-center">{t('Cognition')}</h3>
                  </div>
                </div>
                <div className="flex items-center h-32 sm:h-32 p-2 p-2 border-r-2 border-b-2 border-gray-300 sm:border-0">
                  <div className="relative w-full">
                    <div className="flex mx-auto w-12 h-12 p-2 mt-2 self-center items-center bg-gray-800">
                      <Heart className="fill-current text-white mx-auto" />
                    </div>
                    <h3 className="absolute w-full mt-2 truncate text-xs text-blue-800 font-semibold font-sans uppercase text-center">{t('Emotion')}</h3>
                  </div>
                </div>
                <div className="flex items-center h-32 sm:h-32 p-2 border-b-2 border-gray-300 sm:border-0">
                  <div className="relative w-full">
                    <div className="flex mx-auto w-12 h-12 p-2 mt-2 self-center items-center bg-gray-800">
                      <Chat className="fill-current text-white mx-auto" />
                    </div>
                    <h3 className="absolute w-full mt-2 truncate text-xs text-blue-800 font-semibold font-sans uppercase text-center">{t('Dialogue')}</h3>
                  </div>
                </div>
                <div className="flex items-center h-32 sm:h-32 p-2 border-r-2 border-gray-300 sm:border-0">
                  <div className="relative w-full">
                    <div className="flex mx-auto w-12 h-12 p-2 mt-2 self-center items-center bg-gray-800">
                      <Waveform className="fill-current text-white mx-auto" />
                    </div>
                    <h3 className="absolute w-full mt-2 truncate text-xs text-blue-800 font-semibold font-sans uppercase text-center">{t('Speech Synthesis')}</h3>
                  </div>
                </div>
                <div className="flex items-center h-32 sm:h-32 p-2">
                  <div className="relative w-full">
                    <div className="flex mx-auto w-12 h-12 p-2 mt-2 self-center items-center bg-gray-800">
                      <Render className="fill-current text-white mx-auto" />
                    </div>
                    <h3 className="absolute w-full mt-2 truncate text-xs text-blue-800 font-semibold font-sans uppercase text-center">{t('Realistic Rendering')}</h3>
                  </div>
                </div>
              </div>
              <div className="flex items-center relative py-6 sm:px-6">
                <p>{t('A user’s interaction with our AI is an intricate exchange – just like any other personal conversation between two individuals. Each aspect of the conversation loop is a unique component to actualize the AI, its personality, and its presentation. These building blocks can be used in total for a unique \"end-to end\" solution or can be used in a piecemeal \"plug-and-play\" approach with other existing platforms or technologies.')}</p>
              </div>
            </div>
          </div>
        </section>

        </Layout>
      </div>
      )
}
